import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-container">
      <h1>Contact Brody</h1>
      <div className="contact-details">
        <p><strong>Email:</strong> <a href="mailto:vancouvertileandbath@gmail.com">vancouvertileandbath@gmail.com</a></p>
        <p><strong>Phone:</strong> <a href="tel:+13609773487">(360) 977 3487</a></p>
      </div>
    </div>
  );
}

export default Contact;